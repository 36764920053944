var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "backend-location-autocomplete" },
    [
      _c(
        "v-autocomplete",
        {
          staticClass: "pt-0 pb-0",
          attrs: {
            items: _vm.locationList,
            loading: _vm.loading,
            "search-input": _vm.search,
            color: "info",
            clearable: "",
            "item-text": "name",
            "item-value": "id",
            label: `${_vm.label} Location`,
            "return-object": "",
            required: "",
            "close-on-content-click": "",
            "item-width": "200",
            filter: (v) => v,
          },
          on: {
            "update:searchInput": function ($event) {
              _vm.search = $event
            },
            "update:search-input": function ($event) {
              _vm.search = $event
            },
            change: function ($event) {
              return _vm.pushLocation()
            },
            "click:clear": function ($event) {
              return _vm.removeLocation()
            },
          },
          scopedSlots: _vm._u([
            {
              key: "prepend",
              fn: function () {
                return [
                  _vm.$vuetify.breakpoint.lgAndUp
                    ? _c("v-icon", { attrs: { color: _vm.marker } }, [
                        _vm._v(" mdi-map-marker "),
                      ])
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            {
              key: "item",
              fn: function (data) {
                return [
                  data.item.disabled === true
                    ? [
                        _c("v-list-item-content", {
                          domProps: { textContent: _vm._s(data.item.name) },
                        }),
                      ]
                    : [
                        _c("v-list-item-avatar", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.shortLocationName(data.item.name)) +
                              " "
                          ),
                        ]),
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", {
                              domProps: { innerHTML: _vm._s(data.item.name) },
                            }),
                            _c("v-list-item-subtitle", {
                              domProps: {
                                innerHTML: _vm._s(data.item.address_preview),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                ]
              },
            },
          ]),
          model: {
            value: _vm.location,
            callback: function ($$v) {
              _vm.location = $$v
            },
            expression: "location",
          },
        },
        [
          _c(
            "v-list-item",
            { attrs: { slot: "append-item" }, slot: "append-item" },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "", small: "" },
                  on: {
                    click: function ($event) {
                      return _vm.locationForm()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-plus")]), _vm._v(" Add Location ")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }